import React, { Suspense } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import LoginPage from './components/Login/LoginPage'
import './scss/style.scss'
import { useAuth0 } from '@auth0/auth0-react'
import LoadingPage from './components/LoadingPage/LoadingPage'
// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

const App = () => {
  const { isAuthenticated, isLoading } = useAuth0()

  if (isLoading) {
    return <LoadingPage />
  }

  if (!isAuthenticated) {
    return <LoginPage />
  }

  return (
    <HashRouter>
      <Suspense fallback={<CSpinner color="primary" />}>
        <Switch>
          <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
        </Switch>
      </Suspense>
    </HashRouter>
  )
}

export default App
