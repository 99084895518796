import React from 'react'

import { CCol, CRow, CContainer, CCard, CCardGroup, CCardImage, CCardBody } from '@coreui/react-pro'

import AuthenticationButton from '../Buttons/AuthenticationButton'
import MaesosLogo from '../../assets/Logos/6600x1000_black.png'
import loginImageBanner from '../../assets/Pictures/StemNode1.png'
import './LoginPage.css'

import { BrowserView, MobileView } from 'react-device-detect'

const LoginPage = () => {
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <BrowserView>
          <CRow className="justify-content-center">
            <CCol md={8}>
              <CCardGroup>
                <CCard className="mb-3">
                  <CCardImage
                    src={loginImageBanner}
                    className="field-cards"
                    alt={'login Image banner'}
                  />
                </CCard>
                <CCard className="mb-3">
                  <CCardBody>
                    <CRow className="justify-content-center">
                      <CCol md={10}>
                        <br />
                        <img height={28} src={MaesosLogo} alt={'Maesos Logo'} />
                        <div className="top-text text-small-emphasis">
                          <p>
                            Bringing the power of agriculture insights into the palm of your hand
                          </p>
                        </div>
                        <div className="login-component">
                          <h6 className="text-high-emphasis">
                            Please Sign In to access your dashboard
                          </h6>
                          <br />
                          <div className="d-grid gap-2">
                            <AuthenticationButton />
                          </div>
                        </div>
                        <div className="align-items-bottom bottom-text">
                          <span className="text-small-emphasis">Dont have an account? </span>
                          <a
                            href="https://maesos.com/?page_id=126"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Contact us
                          </a>
                          <span className="text-small-emphasis"> today to get started</span>
                        </div>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </BrowserView>
        <MobileView>
          <CRow className="justify-content-center">
            <CCol md={8}>
              <CCardGroup>
                <CCard className="mb-3">
                  <CCardBody>
                    <CRow className="justify-content-center">
                      <CCol md={10}>
                        <br />
                        <img height={28} src={MaesosLogo} alt={'maesos-logo'} />
                        <div className="top-text text-small-emphasis">
                          <p>
                            Bringing the power of agriculture insights into the palm of your hand
                          </p>
                        </div>
                        <div className="login-component">
                          <h6 className="text-high-emphasis">
                            Please Sign In to access your dashboard
                          </h6>
                          <br />
                          <div className="d-grid gap-2">
                            <AuthenticationButton />
                          </div>
                        </div>
                        <div className="align-items-bottom bottom-text">
                          <span className="text-small-emphasis">Dont have an account? </span>
                          <a
                            href="https://maesos.com/?page_id=126"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Contact us
                          </a>
                          <span className="text-small-emphasis"> today to get started</span>
                        </div>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </MobileView>
      </CContainer>
    </div>
  )
}
export default LoginPage
