import React from 'react'
import { CSpinner } from '@coreui/react-pro'

const LoadingPage = () => {
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center justify-content-center">
      <CSpinner color="primary" />
      <h3>Loading Authentication...</h3>
    </div>
  )
}

export default LoadingPage
