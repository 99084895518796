import React from 'react'

import { CButton } from '@coreui/react-pro'

import { useAuth0 } from '@auth0/auth0-react'

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0()
  return (
    <CButton
      className="d-grid gap-2"
      shape="rounded-pill"
      color="primary"
      onClick={() => loginWithRedirect()}
    >
      Log In
    </CButton>
  )
}

export default LoginButton
